import React from 'react';
import { Helmet } from 'react-helmet';
import Icons from '../../icons/Icons';

const HelmetComponent = ({ title }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <link rel="icon" href={Icons.Y} type="image/x-icon" /> {/* Add favicon */}
    </Helmet>
  );
};

export default HelmetComponent;
