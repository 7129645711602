import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Box
} from '@mui/material';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded';
import './customTable.scss';
import Auth from "../../auth/Auth";
import BASE_URL from "../../auth/baseURL";
import Loading from '../../components/Loading/Loading';
import { Link } from 'react-router-dom';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import Notification from "../../components/Notification/Notification";
import jsPDF from 'jspdf';
import NunitoFont from '../../fonts/nunito.js';

const userId = Auth.getUserId();

const CustomTable = () => {
    const [records, setRecords] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [notificationOpen, setNotificationOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationTitle, setNotificationTitle] = useState('');
    const [notificationType, setNotificationType] = useState('success');

    // Function to fetch initial records
    const fetchRecords = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/records/${userId}/get/all`);
            setRecords(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    // Function to delete a record
    const deleteRecord = async (record_id) => {
        try {
            await axios.delete(`${BASE_URL}/records/${userId}/${record_id}`);
            // Update the state to remove the deleted record
            setRecords((prevRecords) => prevRecords.filter(record => record.record_id !== record_id));

            // Show success notification
            setNotificationTitle('Record Deleted');
            setNotificationMessage(`Record "${record_id}" has been deleted successfully.`);
            setNotificationType('success');
            setNotificationOpen(true);
        } catch (error) {
            console.error("Error deleting record", error);
            // Show error notification
            setNotificationTitle('Error Deleting Record');
            setNotificationMessage(`An error occurred while deleting record "${record_id}".`);
            setNotificationType('error');
            setNotificationOpen(true);
        }
    };

    // Function to copy the link
    const copyLink = (recordId) => {
        const link = `${window.location}/${userId}/${recordId}`; // Create the link
        navigator.clipboard.writeText(link)
            .then(() => {
                // Show notification on successful copy
                setNotificationTitle('Link Copied');
                setNotificationMessage(`Link to record "${recordId}" has been copied to clipboard.`);
                setNotificationType('success');
                setNotificationOpen(true);
            })
            .catch(err => {
                console.error('Failed to copy the link:', err);
                // Show error notification
                setNotificationTitle('Copy Error');
                setNotificationMessage('Failed to copy the link.');
                setNotificationType('error');
                setNotificationOpen(true);
            });
    };

    // Function to generate and download PDF for a specific record
    const downloadPDF = (record) => {
        const doc = new jsPDF();
    
        // Add the Nunito font to jsPDF
        doc.addFileToVFS("Nunito.ttf", NunitoFont);
        doc.addFont("Nunito.ttf", "Nunito", "normal");
        doc.setFont("Nunito");
    
        // Header section
        doc.setFontSize(18);
        doc.text(`File Name: ${record.fileName}`, 20, 20); // File Name
        
        doc.setFontSize(14);
        doc.text("Operator:", 20, 40); // Operator header
        doc.setFontSize(12);
        doc.text(record.operator || "No operator", 50, 40); // Operator value
    
        doc.setFontSize(14);
        doc.text("Script:", 20, 50); // Script header
        doc.setFontSize(12);
        doc.text(record.script || "No script", 50, 50); // Script value
    
        // Analysis section (Bold)
        doc.setFontSize(16);
        doc.setFont("Nunito", "bold");
        doc.text("Analysis:", 20, 70); // Analysis header
    
        doc.setFont("Nunito", "normal");
        doc.setFontSize(12);
        doc.text(record.details[0].analysis || "No analysis available", 20, 80, { maxWidth: 170 }); // Analysis content
    
        // Add a new page for the Transcript
        doc.addPage();
    
        // Transcript section (Bold)
        doc.setFont("Nunito", "bold");
        doc.setFontSize(16);
        doc.text("Transcript:", 20, 20); // Transcript header
    
        doc.setFont("Nunito", "normal");
        doc.setFontSize(12);
        doc.text(record.details[0].transcript || "No transcript available", 20, 30, { maxWidth: 170 }); // Transcript content

        doc.addPage();

        doc.setFont("Nunito", "bold");
        doc.setFontSize(16);
        doc.text("Client profile:", 20, 20); // Transcript header
    
        doc.setFont("Nunito", "normal");
        doc.setFontSize(12);
        doc.text(record.details[0].profile || "No client profile available", 20, 30, { maxWidth: 170 }); // Transcript content
    
        // Save the generated PDF
        doc.save(`Record_${record.record_id}.pdf`);
    };

    // Function to update records based on SSE
    const handleSSEUpdates = (updatedRecord) => {
        const { record_id, duration } = updatedRecord;
        setRecords((prevRecords) => {
            const recordIndex = prevRecords.findIndex(record => record.record_id === record_id);

            if (recordIndex !== -1) {
                // Update the status of the matching record
                const updatedRecords = [...prevRecords];
                updatedRecords[recordIndex] = {
                    ...updatedRecords[recordIndex],
                    duration: duration,
                    details: "Details available"
                };

                // Trigger notification that the file is ready
                setNotificationTitle('File Ready');
                setNotificationMessage(`"${updatedRecords[recordIndex].fileName}" is ready with updated duration: ${duration}`);
                setNotificationType('success');
                setNotificationOpen(true);

                return updatedRecords;
            } else {
                return prevRecords;
            }
        });
    };

    // Listen for real-time updates using SSE
    useEffect(() => {
        fetchRecords();
        const eventSource = new EventSource(`${BASE_URL}/records/${userId}/updates/live`);

        eventSource.onmessage = (event) => {
            console.log('Received SSE update:', event.data);
            const updatedRecord = JSON.parse(event.data);
            handleSSEUpdates(updatedRecord);
        };

        eventSource.onerror = (error) => {
            console.error('SSE connection error:', error);
            eventSource.close();
        };

        return () => {
            eventSource.close();
        };
    }, []);

    // Handle page change
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Handle rows per page change
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Determine the status color based on whether details are available
    const getStatusColor = (record) => {
        return record.details && record.details.length > 0 ? 'green' : 'yellow';
    };

    // Slice the rows to display the current page's data
    const displayedRows = records.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <Box sx={{ width: '100%' }}>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <TableContainer component={Paper} sx={{ backgroundColor: 'rgb(23, 23, 23)', border: '1px solid rgb(50, 50, 50)', borderRadius: '5px' }}>
                        <Table aria-label="basic table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ width: '25%', color: 'white' }}>File name</TableCell>
                                    <TableCell sx={{ width: '10%', color: 'white', textAlign: 'center' }}>Duration</TableCell>
                                    <TableCell sx={{ width: '10%', color: 'white', textAlign: 'center' }}>Operator</TableCell>
                                    <TableCell sx={{ width: '10%', color: 'white', textAlign: 'center' }}>Script</TableCell>
                                    <TableCell sx={{ width: '10%', color: 'white', textAlign: 'center' }}>Date Added</TableCell>
                                    <TableCell sx={{ width: '10%', color: 'white', textAlign: 'center' }}>Status</TableCell>
                                    <TableCell sx={{ width: '25%', color: 'white', textAlign: 'center' }}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {displayedRows.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell sx={{ color: 'white', borderBottom: 'none' }}>
                                            <Link to={`${userId}/${row.record_id}`} className="blue" style={{ textDecoration: 'none' }}>
                                                {row.fileName}
                                            </Link>
                                        </TableCell>
                                        <TableCell sx={{ color: 'white', borderBottom: 'none', textAlign: 'center' }}>{row.duration}</TableCell>
                                        <TableCell sx={{ color: 'white', borderBottom: 'none', textAlign: 'center' }}>{row.operator}</TableCell>
                                        <TableCell sx={{ color: 'white', borderBottom: 'none', textAlign: 'center' }}>{row.script}</TableCell>
                                        <TableCell sx={{ color: 'white', borderBottom: 'none', textAlign: 'center' }}>{new Date(row.dateAdded).toLocaleDateString()}</TableCell>
                                        <TableCell sx={{ color: 'white', borderBottom: 'none', textAlign: 'center' }}>
                                            <CircleRoundedIcon className={`status ${getStatusColor(row)}`} />
                                        </TableCell>
                                        <TableCell sx={{ color: 'white', borderBottom: 'none' }}>
                                            <div className="actions">
                                                <DownloadRoundedIcon
                                                    className="icon download"
                                                    onClick={() => downloadPDF(row)} // Download PDF on click
                                                    style={{ cursor: 'pointer' }}
                                                />
                                                <IosShareRoundedIcon 
                                                    className="icon share" 
                                                    onClick={() => copyLink(row.record_id)} // Copy link on click
                                                    style={{ cursor: 'pointer' }}
                                                />
                                                <DeleteOutlineRoundedIcon
                                                    className="icon bin"
                                                    onClick={() => deleteRecord(row.record_id)}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        component="div"
                        count={records.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[5, 10, 15, 20, 25]}
                        sx={{ color: 'white' }}
                    />
                    {/* Render the Notification component */}
                    <Notification
                        title={notificationTitle}
                        message={notificationMessage}
                        open={notificationOpen}
                        onClose={() => setNotificationOpen(false)}
                        type={notificationType}
                    />
                </>
            )}
        </Box>
    );
};

export default CustomTable;
