import React from "react";
import "./records.scss";
import Sidebar from "../../components/Sidebar/Sidebar";
import CustomTable from "./CustomTable";
import HelmetComponent from "../../components/Helmet/Helmet";

const Records = () => {
    return (
        <div className="records">
            <HelmetComponent title="Records"/>
            <Sidebar />
            <div className="homeContainer">
                <div className="content">
                    <span className="title">Records</span>
                    <CustomTable />
                </div>
            </div>
        </div>
    )
}

export default Records;