import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Use useNavigate for redirection
import axios from 'axios';
import Auth from '../../auth/Auth';
import BASE_URL from '../../auth/baseURL';
import Sidebar from '../../components/Sidebar/Sidebar';
import './singleScript.scss';
import Loading from '../../components/Loading/Loading';

const SingleScript = () => {
  const { scriptId } = useParams();
  const [script, setScript] = useState(null);
  const [loading, setLoading] = useState(true);
  const userId = Auth.getUserId();
  const navigate = useNavigate(); // For navigation after deletion

  // Delete script function
  const deleteScript = async () => {
    try {
      const response = await axios.delete(`${BASE_URL}/users/${userId}/script/${scriptId}`);
      console.log(response.data.msg);
      // After deleting the script, redirect back to settings or display a message
      navigate('/settings'); // Redirect to settings page
    } catch (error) {
      console.error('Error deleting script:', error.response?.data?.msg || error.message);
    }
  };

  // Fetch the script data
  useEffect(() => {
    const fetchScript = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/users/${userId}/script/${scriptId}`
        );
        setScript(response.data);
      } catch (error) {
        console.error(
          'Error fetching script:',
          error.response?.data?.msg || error.message
        );
      } finally {
        setLoading(false);
      }
    };

    fetchScript();
  }, [userId, scriptId]);

  // Loading state
  if (loading) {
    return (
      <div className="singleScript">
        <Sidebar />
        <div className="homeContainer">
          <Loading />
        </div>
      </div>
    );
  }

  // Script not found
  if (!script) {
    return (
      <div className="singleScript">
        <Sidebar />
        <div className="homeContainer">
          <p>Script not found</p>
        </div>
      </div>
    );
  }

  // Render the script and the delete button
  return (
    <div className="singleScript">
      <Sidebar />
      <div className="homeContainer">
        <div className="row">
          <h2>{script.title}</h2>
          <span className='delete' onClick={deleteScript}>delete</span> {/* Correctly pass the deleteScript */}
        </div>
        <pre className="scriptContent">{script.content}</pre>
      </div>
    </div>
  );
};

export default SingleScript;
