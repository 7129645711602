import React, { useState, useEffect, useRef } from 'react';
import './CustomDropdown.scss';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

const CustomDropdown = ({ operators, file, className }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOperator, setSelectedOperator] = useState('-');
    const dropdownRef = useRef(null);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleOptionClick = (operatorName) => {
        setSelectedOperator(operatorName);
        setIsOpen(false);
    };

    // Close dropdown if clicked outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <div ref={dropdownRef} className={`custom-dropdown ${className}`} data-file={file.name} data-value={selectedOperator}>
            <div className="dropdown-header" onClick={toggleDropdown}>
                {selectedOperator}
                <KeyboardArrowDownRoundedIcon className={`arrow ${isOpen ? 'up' : 'down'}`} />
            </div>
            {isOpen && (
                <ul className="dropdown-list">
                    <li className={selectedOperator === '-' ? 'selected' : ''} onClick={() => handleOptionClick('-')}>
                        -
                    </li>
                    {operators.map((operator) => (
                        <li
                            key={operator._id}
                            className={selectedOperator === operator.name ? 'selected' : ''}
                            onClick={() => handleOptionClick(operator.name)}
                        >
                            {operator.name}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default CustomDropdown;
