class Auth {
    constructor() {
        this.authenticated = !!localStorage.getItem('token');  // Initialize based on localStorage
        this.token = localStorage.getItem('token');
        this.userId = localStorage.getItem('userId');
    }

    login(token, userId) {
        this.authenticated = true;
        this.token = token;
        this.userId = userId;

        // Store token and userId in localStorage for persistence
        localStorage.setItem('token', token);
        localStorage.setItem('userId', userId);
    }

    logout() {
        this.authenticated = false;
        this.token = null;
        this.userId = null;

        // Clear token and userId from localStorage
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
    }

    isAuthenticated() {
        // Check localStorage for token to persist authentication state
        return this.authenticated;
    }

    getToken() {
        return this.token;
    }

    getUserId() {
        return this.userId;
    }
}

const authInstance = new Auth();
export default authInstance;
