import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const Notification = ({ title, message, open, onClose, type }) => {
  const getStyles = () => {
    if (type === 'success') {
      return {
        backgroundColor: 'green',
        borderColor: 'darkgreen',
      };
    } else if (type === 'error') {
      return {
        backgroundColor: 'red',
        borderColor: 'darkred',
      };
    }
  };

  const styles = getStyles();

  return (
    <Snackbar
      open={open}
      autoHideDuration={3000} // Fade out after 2 seconds
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    >
      <Alert
        onClose={onClose}
        severity={type}
        sx={{
          backgroundColor: styles.backgroundColor,
          border: `1px solid ${styles.borderColor}`,
          color: '#fff',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
        iconMapping={{
          success: (
            <svg
              width="24px" // Increase icon size here
              height="24px"
              fill="white"
              viewBox="0 0 24 24"
            >
              <path d="M9 16.2l-3.5-3.5 1.4-1.4L9 13.4l7.2-7.2 1.4 1.4z" />
            </svg>
          ),
          error: (
            <svg
              width="24px" // Increase icon size here
              height="24px"
              fill="white"
              viewBox="0 0 24 24"
            >
              <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm5 13.6l-1.4 1.4L12 13.4l-3.6 3.6-1.4-1.4L10.6 12 7 8.4l1.4-1.4L12 10.6l3.6-3.6 1.4 1.4L13.4 12l3.6 3.6z" />
            </svg>
          ),
        }}
      >
        <strong>{title}</strong>
        <div>{message}</div>
      </Alert>
    </Snackbar>
  );
};

export default Notification;
