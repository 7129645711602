import React, { useState } from "react";
import "./sidebar.scss";
import { Link, useLocation } from "react-router-dom";
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import BugReportRoundedIcon from '@mui/icons-material/BugReportRounded';
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import CloudQueueRoundedIcon from '@mui/icons-material/CloudQueueRounded';
import Icons from "../../icons/Icons";
import RadioButtonCheckedRoundedIcon from '@mui/icons-material/RadioButtonCheckedRounded';
import { useAuth } from '../../auth/AuthContext.js';

const Sidebar = () => {
    const { logout } = useAuth();
    const location = useLocation();
    const [activeItem, setActiveItem] = useState(location.pathname); // default to current route

    const handleSetActive = (path) => {
        setActiveItem(path);
    };

    return (
        <div className="sidebar">
            <div className="top">
                <Link to="/" style={{ textDecoration: "none" }}>
                    <div className="logorow">
                        <img src={Icons.logo_light} alt="icon" className="icon" />
                    </div>
                </Link>
            </div>
            <div className="center">
                <ul>
                    <p className="title">MAIN</p>
                    <Link to="/quickstart" style={{ textDecoration: "none" }} onClick={() => handleSetActive('/quickstart')}>
                        <li className={activeItem === '/quickstart' ? "active" : ""}>
                            <PlayArrowRoundedIcon className="icon" />
                            <span>QuickStart</span>
                        </li>
                    </Link>

                    <Link to="/upload" style={{ textDecoration: "none" }} onClick={() => handleSetActive('/upload')}>
                        <li className={activeItem === '/upload' ? "active" : ""}>
                            <CloudQueueRoundedIcon className="icon" />
                            <span>Upload</span>
                        </li>
                    </Link>

                    <Link to="/" style={{ textDecoration: "none" }} onClick={() => handleSetActive('/')}>
                        <li className={activeItem === '/' ? "active" : ""}>
                            <DashboardRoundedIcon className="icon" />
                            <span>Dashboard</span>
                        </li>
                    </Link>

                    <Link to="/records" style={{ textDecoration: "none" }} onClick={() => handleSetActive('/records')}>
                        <li className={activeItem === '/records' ? "active" : ""}>
                            <RadioButtonCheckedRoundedIcon className="icon" />
                            <span>Records</span>
                        </li>
                    </Link>

                    <Link to="/analytics" style={{ textDecoration: "none" }} onClick={() => handleSetActive('/analytics')}>
                        <li className={activeItem === '/analytics' ? "active" : ""}>
                            <QueryStatsRoundedIcon className="icon" />
                            <span>Analytics</span>
                        </li>
                    </Link>

                    <Link to="/abtesting" style={{ textDecoration: "none" }} onClick={() => handleSetActive('/abtesting')}>
                        <li className={activeItem === '/abtesting' ? "active" : ""}>
                            <BugReportRoundedIcon className="icon" />
                            <span>A/B Testing</span>
                        </li>
                    </Link>

                    <p className="title">USER</p>

                    <Link to="/settings" style={{ textDecoration: "none" }} onClick={() => handleSetActive('/settings')}>
                        <li className={activeItem === '/settings' ? "active" : ""}>
                            <SettingsRoundedIcon className="icon" />
                            <span>Settings</span>
                        </li>
                    </Link>

                    <Link to="/" style={{ textDecoration: "none" }} onClick={() => handleSetActive('/billing')}>
                        <li className={activeItem === '/billing' ? "active" : ""}>
                            <CreditCardRoundedIcon className="icon" />
                            <span>Billing</span>
                        </li>
                    </Link>
                    <p className="title">ACCOUNT</p>
                    <Link to="/profile" style={{ textDecoration: "none" }} onClick={() => handleSetActive('/profile')}>
                        <li className={activeItem === '/profile' ? "active" : ""}>
                            <Person2RoundedIcon className="icon" />
                            <span>Profile</span>
                        </li>
                    </Link>
                    <li onClick={logout}>
                        <ExitToAppRoundedIcon className="icon" />
                        <span>Log out</span>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Sidebar;
