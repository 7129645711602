import React, { useReducer } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './login.scss';
import { useAuth } from '../../auth/AuthContext';
import BASE_URL from '../../auth/baseURL';
import Icons from "../../icons/Icons";
import HelmetComponent from '../Helmet/Helmet';

const initialState = {
  email: '',
  password: '',
  message: '',
  loading: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_EMAIL':
      return { ...state, email: action.payload };
    case 'SET_PASSWORD':
      return { ...state, password: action.payload };
    case 'SET_MESSAGE':
      return { ...state, message: action.payload };
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};

const Login = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    dispatch({ type: 'SET_LOADING', payload: true });

    try {
        const response = await axios.post(`${BASE_URL}/auth/login`, {
            email: state.email,
            password: state.password,
        });

        const { user, token } = response.data;

        if (!user || !user._id) {
            throw new Error('User ID not found in the response');
        }

        // Log values for debugging
        console.log("Login successful:", { token, userId: user._id });

        login(token, user._id);

        dispatch({ type: 'SET_MESSAGE', payload: 'Login successful!' });
        navigate('/profile');
        window.location.reload();
    } catch (error) {
        const errorMsg = error.response?.data?.msg || 'Failed to login. Please try again.';
        dispatch({ type: 'SET_MESSAGE', payload: errorMsg });
    } finally {
        dispatch({ type: 'SET_LOADING', payload: false });
    }
};


  return (
    <div className="login">
      <HelmetComponent title="Log in"/>
      <img src={Icons.logo_light} alt="icon" className="icon" />
      <div className="box1">
        <form onSubmit={handleLogin} className='box'>
          <h2>Login</h2>

          <input
            className="input"
            type="email"
            placeholder="Email"
            value={state.email}
            onChange={(e) => dispatch({ type: 'SET_EMAIL', payload: e.target.value })}
            disabled={state.loading}
          />

          <input
            className="input"
            type="password"
            placeholder="Password"
            value={state.password}
            onChange={(e) => dispatch({ type: 'SET_PASSWORD', payload: e.target.value })}
            disabled={state.loading}
          />

          <button type="submit" disabled={state.loading} className='button'>
            {state.loading ? 'Logging in...' : 'Login'}
          </button>
          {state.message && <p className="message">{state.message}</p>}
          <span className='signup'>Don't have an account? <Link to="/register" className='link'>Sign up</Link></span>
        </form>
      </div>
    </div>
  );
};

export default Login;
