import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"; // Import useParams
import "./singleRecord.scss";
import Sidebar from "../../components/Sidebar/Sidebar";
import axios from "axios";
import Loading from "../../components/Loading/Loading.jsx";
import BASE_URL from "../../auth/baseURL.js";

const SingleRecord = () => {
    const { recordId } = useParams();  // Get recordId from the URL
    const { userId } = useParams();

    const [analysis, setAnalysis] = useState(null); // State to store the analysis
    const [transcript, setTranscript] = useState(null); // State to store the analysis
    const [profile, setProfile] = useState(null); // State to store the analysis
    const [loading, setLoading] = useState(true); // State for loading status
    const [error, setError] = useState(null); // State for error handling


    const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    useEffect(() => {
        const fetchRecordDetails = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`${BASE_URL}/records/${userId}/${recordId}`);
                console.log(response)
                sleep(1500);
                setAnalysis(response.data.details[0].analysis);
                setTranscript(response.data.details[0].transcript);
                setProfile(response.data.details[0].profile);
                console.log(response);
            } catch (error) {
                console.error("Error fetching record details:", error);
                setError("Failed to load the record details.");
            } finally {
                setLoading(false);
            }
        };

        fetchRecordDetails();
    }, [recordId, userId]);

    if (loading) {
        return (
            <Loading />
        )
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className="singleRecord">
            <Sidebar />
            <div className="homeContainer">
                <div className="content">
                    <span className="ID">Record ID: {recordId}</span>
                    <div className="blocks">
                        <div className="block">
                            <span className="title">Analysis:</span>
                            <span className="text">{analysis}</span>
                        </div>
                        <div className="block">
                            <span className="title">Transcript:</span>
                            <span className="text">{transcript}</span>
                        </div>
                        <div className="block">
                            <span className="title">Client Profile:</span>
                            <span className="text">{profile}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SingleRecord;
