import React from "react";
import "./profile.scss";
import Sidebar from "../../components/Sidebar/Sidebar";
import Auth from "../../auth/Auth";
import HelmetComponent from "../../components/Helmet/Helmet";

const userId = Auth.getUserId();
const token = Auth.getToken()

const Profile = () => {
    return (
        <div className="profile">
            <HelmetComponent title="Profile"/>
            <Sidebar />
            <div className="homeContainer">
                <h1>User Profile</h1>
                <h1>User ID: {userId}</h1>
                <h1>Token: {token}</h1>
            </div>
        </div>
    );
}

export default Profile;
