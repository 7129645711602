import React, { useReducer, useMemo, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import CountrySelect from 'react-select-country-list';
import './register.scss';
import Icons from "../../icons/Icons";
import axios from 'axios';
import BASE_URL from "../../auth/baseURL";
import { useAuth } from '../../auth/AuthContext'; // Import useAuth
import HelmetComponent from '../Helmet/Helmet';

const initialState = {
  email: '',
  password: '',
  organizationName: '',
  name: '',
  phone: '',
  location: null, // This will store the selected country
  message: '',
  loading: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_EMAIL':
      return { ...state, email: action.payload };
    case 'SET_PASSWORD':
      return { ...state, password: action.payload };
    case 'SET_ORGANIZATION_NAME':
      return { ...state, organizationName: action.payload };
    case 'SET_NAME':
      return { ...state, name: action.payload };
    case 'SET_PHONE':
      return { ...state, phone: action.payload };
    case 'SET_LOCATION':
      return { ...state, location: action.payload }; // location will now represent the selected country
    case 'SET_MESSAGE':
      return { ...state, message: action.payload };
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};

const Register = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { login } = useAuth();
  const navigate = useNavigate();
  
  const countryOptions = useMemo(() => CountrySelect().getData(), []);

  const handleRegister = useCallback(async (e) => {
    e.preventDefault();
    dispatch({ type: 'SET_LOADING', payload: true });

    try {
      const response = await axios.post(`${BASE_URL}/auth/register`, {
        email: state.email,
        password: state.password,
        organization: state.organizationName, // Correctly mapping to "organization"
        name: state.name,
        phone: state.phone,
        country: state.location ? state.location.label : 'Unknown', // Correctly mapping to "country"
      });

      const { user, token } = response.data;
      if (!user || !user._id) {
        throw new Error('User ID not found in the response');
      }

      // Log in the user after successful registration
      login(token, user._id);

      dispatch({ type: 'SET_MESSAGE', payload: 'Account created successfully!' });
      navigate('/profile'); // Redirect to the profile page or homepage
    } catch (error) {
      const errorMsg = error.response?.data?.msg || 'Failed to create account.';
      dispatch({ type: 'SET_MESSAGE', payload: errorMsg });
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  }, [state, login, navigate]);

  const customSelectStyles = {
    control: (base) => ({
      ...base,
      border: '1px solid rgb(50, 50, 50)',
      borderRadius: '4px',
      color: 'white',
      backgroundColor: 'rgb(23, 23, 23)',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'rgb(23, 23, 23)' : 'rgb(23, 23, 23)',
      color: state.isSelected ? 'white' : 'white',
      '&:hover': {
        backgroundColor: 'rgb(27, 27, 27)',
        cursor: 'pointer'
      },
    }),
  };

  return (
    <div className="register-container">
      <HelmetComponent title="Register"/>
      <img src={Icons.logo_light} alt="icon" className="icon" />
      <form onSubmit={handleRegister}>
        <h2>Register</h2>

        <input
          type="text"
          placeholder="Organization Name"
          value={state.organizationName}
          onChange={(e) => dispatch({ type: 'SET_ORGANIZATION_NAME', payload: e.target.value })}
        />

        <input
          type="text"
          placeholder="Name"
          value={state.name}
          onChange={(e) => dispatch({ type: 'SET_NAME', payload: e.target.value })}
        />

        <input
          type="tel"
          placeholder="Phone"
          value={state.phone}
          onChange={(e) => dispatch({ type: 'SET_PHONE', payload: e.target.value })}
        />

        <input
          type="email"
          placeholder="Email"
          value={state.email}
          onChange={(e) => dispatch({ type: 'SET_EMAIL', payload: e.target.value })}
        />

        <input
          type="password"
          placeholder="Password"
          value={state.password}
          onChange={(e) => dispatch({ type: 'SET_PASSWORD', payload: e.target.value })}
        />
        
        <Select
          options={countryOptions}
          value={state.location}
          onChange={(value) => dispatch({ type: 'SET_LOCATION', payload: value })} // Sets country as location
          placeholder="Select your country"
          className='select'
          styles={customSelectStyles}
        />

        <button type="submit" disabled={state.loading}>
          {state.loading ? 'Registering...' : 'Register'}
        </button>
        {state.message && <p className="message">{state.message}</p>}
        <span className='signin'>Already have an account? <Link to="/login" className='link'>Sign in</Link></span>
      </form>
    </div>
  );
};

export default Register;
