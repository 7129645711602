import React from "react";
import "./quickstart.scss";
import Sidebar from "../../components/Sidebar/Sidebar";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import HelmetComponent from "../../components/Helmet/Helmet";

const Quickstart = () => {
    return (
        <div className="quickstart">
            <HelmetComponent title="Quick Start"/>
            <Sidebar />
            <div className="homeContainer">
                <div className="content">
                    <span className="title">Paste a link to your MP3</span>
                    <div className="setup">
                        <div className="input">
                            <input type="text" className="url" placeholder="URL to your MP3"/>
                            <span className="button">Analyze</span>
                        </div>
                    </div>
                    <div className="tune">
                        <div className="operator">
                            <span className="name">OPERATOR</span>
                            <KeyboardArrowDownRoundedIcon className="dropdown"/>
                        </div>
                        <input type="text" className="script" placeholder="File name"/>
                        <div className="script">
                            <span className="name">SCRIPT</span>
                            <KeyboardArrowDownRoundedIcon className="dropdown"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Quickstart;