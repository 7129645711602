import React from "react";
import "./analytics.scss";
import Sidebar from "../../components/Sidebar/Sidebar";
import HelmetComponent from "../../components/Helmet/Helmet";

const Analytics = () => {
    return (
        <div className="analytics">
            <HelmetComponent title="Analytics"/>
            <Sidebar/>
            <div className="homeContainer">
                Analytics
            </div>
        </div>
    )
}

export default Analytics;