import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import "./upload.scss";
import Sidebar from "../../components/Sidebar/Sidebar";
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import KeyboardDoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded';
import Notification from "../../components/Notification/Notification";
import { useAuth } from '../../auth/AuthContext.js';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import HelmetComponent from "../../components/Helmet/Helmet.jsx";
import BASE_URL from "../../auth/baseURL.js";

// Importing custom dropdown component
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown";

const Upload = () => {
    const [files, setFiles] = useState([]);
    const [fileStatuses, setFileStatuses] = useState({});
    const [operators, setOperators] = useState([]);
    const [scripts, setScripts] = useState([]);
    const [notification, setNotification] = useState({
        open: false,
        type: 'error',
        message: '',
    });
    const [uploading, setUploading] = useState(false);

    const { getUserId } = useAuth();
    const userId = getUserId();

    // Fetch user settings (operators and scripts) on component mount
    useEffect(() => {
        const fetchUserSettings = async () => {
            try {
                const response = await fetch(`${BASE_URL}/users/${userId}/get/settings`);
                if (!response.ok) {
                    throw new Error('Failed to fetch user settings');
                }

                const data = await response.json();
                setOperators(data.operators || []); // Save operators to state
                setScripts(data.scripts || []); // Save scripts to state
            } catch (error) {
                console.error('Error fetching user settings:', error);
                setNotification({
                    open: true,
                    type: 'error',
                    message: 'Failed to load user settings.',
                });
            }
        };

        fetchUserSettings();
    }, [userId]);

    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length > 0) {
            setNotification({
                open: true,
                type: 'error',
                message: 'Unsupported file type. Please upload audio files (MP3, MP4, M4A, WAV, etc.).',
            });
            return;
        }

        const newFileStatuses = acceptedFiles.reduce((acc, file) => {
            acc[file.name] = 'pending';
            return acc;
        }, {});

        setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
        setFileStatuses((prevStatuses) => ({ ...prevStatuses, ...newFileStatuses }));
    }, []);

    const removeFile = (fileName) => {
        setFiles((prevFiles) => prevFiles.filter(file => file.name !== fileName));
        setFileStatuses((prevStatuses) => {
            const updatedStatuses = { ...prevStatuses };
            delete updatedStatuses[fileName];
            return updatedStatuses;
        });
    };

    const handleCloseNotification = () => {
        setNotification({ ...notification, open: false });
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'audio/mpeg': ['.mp3', '.mpga'],
            'audio/mp4': ['.mp4', '.m4a'],
            'audio/wav': ['.wav'],
            'audio/webm': ['.webm'],
        },
        onDrop,
    });

    const analyzeFiles = async () => {
        setUploading(true);

        try {
            for (const file of files) {
                // Get selected operator and script for each file
                const selectedOperator = document.querySelector(`.operator-dropdown[data-file="${file.name}"]`).dataset.value;
                const selectedScript = document.querySelector(`.script-dropdown[data-file="${file.name}"]`).dataset.value;

                const operatorToSend = selectedOperator !== '-' ? selectedOperator : '';
                const scriptToSend = selectedScript !== '-' ? selectedScript : '';

                const formData = new FormData();
                formData.append('audio', file);
                formData.append('operator', operatorToSend);
                formData.append('script', scriptToSend);

                const response = await fetch(`${BASE_URL}/files/${userId}/upload`, {
                    method: 'POST',
                    body: formData,
                });

                if (!response.ok) {
                    throw new Error('Failed to upload file');
                }

                const data = await response.json();
                console.log('File uploaded successfully:', data);

                setFileStatuses((prevStatuses) => ({
                    ...prevStatuses,
                    [file.name]: 'complete',
                }));

                setNotification({
                    open: true,
                    type: 'success',
                    message: `File ${file.name} uploaded successfully!`,
                });
            }
        } catch (error) {
            console.error('Error uploading file:', error);

            setNotification({
                open: true,
                type: 'error',
                message: `Error uploading files. Please try again.`,
            });
        } finally {
            setUploading(false);
        }
    };

    return (
        <div className="upload">
            <HelmetComponent title="Upload"/>
            <Sidebar />
            <div className="homeContainer">
                <div className="content">
                    <div className="space">
                        Upload Audio Files
                        <div {...getRootProps({ className: 'dropzone' })}>
                            <input {...getInputProps()} />
                            <p>Drag & drop some files here, or click to select files (Supported formats: MP3, MP4, M4A, WAV, etc.)</p>
                        </div>
                        <div className="fileList">
                            <h4>Files:</h4>
                            <ul>
                                {files.map((file) => (
                                    <li key={file.name}>
                                        <span>{file.name}</span>
                                        <div className="actions">
                                            <CircleRoundedIcon className={`signal ${fileStatuses[file.name] === 'complete' ? 'green' : 'red'}`}/>

                                            <CustomDropdown 
                                                operators={operators} 
                                                file={file}
                                                className="operator-dropdown"
                                            />
                                                
                                            <CustomDropdown 
                                                operators={scripts.map(script => ({ _id: script._id, name: script.title }))} 
                                                file={file}
                                                className="script-dropdown"
                                            />

                                            <DeleteOutlineRoundedIcon className="icon" onClick={() => removeFile(file.name)}/>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    {files.length > 0 && (
                        <div className="button" onClick={analyzeFiles}>
                            {uploading ? (
                                <span className="name">Uploading...</span>
                            ) : (
                                <span className="name">Analyze</span>
                            )}
                            <KeyboardDoubleArrowRightRoundedIcon className="icon"/>
                        </div>
                    )}
                </div>
            </div>

            {/* Notification */}
            <Notification 
                title={notification.type === 'error' ? 'Error' : 'Success'}
                message={notification.message}
                open={notification.open}
                onClose={handleCloseNotification}
                type={notification.type}
            />
        </div>
    );
};

export default Upload;
