import React from 'react';
import { Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import Dashboard from './pages/dashboard/Dashboard';
import Quickstart from './pages/quickstart/Quickstart';
import Settings from './pages/settings/Settings';
import Analytics from './pages/Analytics/Analytics';
import Login from './components/Login/Login';
import Register from './components/Register/Register';
import Profile from './pages/Profile/Profile';
import Records from './pages/Records/Records';
import ProtectedRoute from './auth/ProtectedRoute';
import Upload from './pages/Upload/Upload';
import { AuthProvider } from './auth/AuthContext'; // Updated to use AuthProvider
import SingleRecord from './pages/Records/SingleRecord';
import SingleScript from './pages/settings/SingleScript';

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          {/* Public Routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />

          {/* Protected Routes */}
          <Route path="/" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
          <Route path="/records" element={<ProtectedRoute><Records /></ProtectedRoute>} />
          {/* <Route path="/records/:recordId" element={<ProtectedRoute><SingleRecord /></ProtectedRoute>} /> */}
          <Route path="records/:userId/:recordId" element={<SingleRecord />} />

          <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
          <Route path="/quickstart" element={<ProtectedRoute><Quickstart /></ProtectedRoute>} />
          <Route path="/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
          <Route path="/settings/:scriptId" element={<ProtectedRoute><SingleScript /></ProtectedRoute>} />

          <Route path="/analytics" element={<ProtectedRoute><Analytics /></ProtectedRoute>} />
          <Route path="/upload" element={<ProtectedRoute><Upload /></ProtectedRoute>} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
