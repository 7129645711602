import React from "react";
import "./dashboard.scss";
import Sidebar from "../../components/Sidebar/Sidebar";
import HelmetComponent from "../../components/Helmet/Helmet";

const Dashboard = () => {
    return (
        <div className="dashboard">
            <HelmetComponent title="Dashboard"/>
            <Sidebar />
            <div className="homeContainer">
                dashboard
                <h1>dskjnflkmdfs</h1>

            </div>
        </div>
    )
}

export default Dashboard;