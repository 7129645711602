import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [userId, setUserId] = useState(localStorage.getItem('userId'));
    const [isAuthenticated, setAuthenticated] = useState(!!localStorage.getItem('token'));
  
    // Function to log in
    const login = (token, userId) => {
      setToken(token);
      setUserId(userId);
      setAuthenticated(true);
  
      // Store token and userId in localStorage for persistence
      localStorage.setItem('token', token);
      localStorage.setItem('userId', userId);
    };
  
    // Function to log out
    const logout = () => {
      setToken(null);
      setUserId(null);
      setAuthenticated(false);
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
  
      // Use window.location.href instead of navigate for full-page redirect
      window.location.href = '/login'; 
    };
  
    // Function to get token
    const getToken = () => {
      return token;
    };
  
    // Function to get userId
    const getUserId = () => {
      return userId;
    };
  
    return (
      <AuthContext.Provider value={{ isAuthenticated, login, logout, getToken, getUserId }}>
        {children}
      </AuthContext.Provider>
    );
  };
  

export const useAuth = () => useContext(AuthContext);
